import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { merge } from 'lodash';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  Box,
  Button,
  Tooltip,
  TextField,
  IconButton,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import { Urls } from 'utils/urls';

// ----------------------------------------------------------------------

const getInitialValues = (order: any) => {
  // eslint-disable-next-line no-underscore-dangle
  const _order = {
    title: '',
    description: '',
  };

  if (order) {
    return merge({}, _order, order);
  }

  return _order;
};

// ----------------------------------------------------------------------

type OrderFormProps = {
  order: any;
  onSave: any;
  onCancel: VoidFunction;
};

export default function OrderForm({ order, onCancel, onSave }: OrderFormProps) {
  const [unitId, setUnitId] = useState<string>('')
  const [units, setUnits] = useState<[]>([])
  const { enqueueSnackbar } = useSnackbar();
  const isCreating = !order;
  const token = localStorage.getItem("accessToken")

  const headers = { headers: {"Content-Type": "application/json", "Authorization": token }}

  const OrderSchema = Yup.object().shape({
    size: Yup.number().max(10000).min(0).required('Max Teile angeben!'),
    name: Yup.string().max(5000).min(3).required('Name angeben!'),
  });

  const formik = useFormik({
    initialValues: getInitialValues(order),
    validationSchema: OrderSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const newOrder = {
          name: values.name,
          size: values.size,
          unit_id: unitId
        };
        if (order?.id) {
          //dispatch(updateEvent(event.id, newEvent));
          enqueueSnackbar('Update order success', { variant: 'success' });
        } else {
          //dispatch(createEvent(newEvent));
          enqueueSnackbar('Create order success', { variant: 'success' });
          onSave(newOrder)
        }
        resetForm();
        onCancel();
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } =
    formik;

  const handleDelete = async () => {
    if (!order.id) return;
    try {
      onCancel();
      enqueueSnackbar('Delete event success', { variant: 'success' });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setUnitId(event.target.value as string);
  };

  useEffect(() => {
    axios.get(Urls.UNITS, headers).then((response) => {
      setUnits(response.data)
      setUnitId(response.data[0].id)
    })
  }, [])

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <DialogContent sx={{ pb: 0, overflowY: 'unset' }}>
          <TextField
            fullWidth
            label="Auftragsname"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            sx={{ mb: 3 }}
          />

          <TextField
            fullWidth
            multiline
            maxRows={1}
            label="Anzahl Teile"
            {...getFieldProps('size')}
            error={Boolean(touched.size && errors.size)}
            helperText={touched.size && errors.size}
            sx={{ mb: 3 }}
          />

          <FormControl fullWidth sx={{mb: 2}}>
            <InputLabel id="unit">Einheit</InputLabel>
              <Select labelId="unit"
                      id="demo-simple-select"
                      value={unitId}
                      label="unit"
                      onChange={handleChange} >
                        { units.map((unit: any) => <MenuItem key={unit.id} value={unit.id}>{ unit.name } | { unit.short }</MenuItem>) }
                </Select>
              </FormControl>
        </DialogContent>

        <DialogActions>
          {!isCreating && (
            <Tooltip title="Delete Event">
              <IconButton onClick={handleDelete}>
                <Icon icon={trash2Fill} width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Button type="button" variant="outlined" color="inherit" onClick={onCancel}>
            Abbrechen
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            loadingIndicator="Loading..."
          >
            Speichern
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}
