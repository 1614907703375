import { DialogTitle } from '@mui/material';
import axios from 'axios';
import { Urls } from 'utils/urls';
import { DialogAnimate } from '../../components/animate';
import OrderForm from './OrderForm';
import { useSnackbar } from "notistack";

export default function AddOrderModal({ open = false, onSaved = () => {}, onCallback = () => {} }: { open: boolean, onSaved: VoidFunction, onCallback: VoidFunction }){
    const selectedReport: boolean = false
    const { enqueueSnackbar } = useSnackbar();

    const token = localStorage.getItem("accessToken")

    const headers = { headers: {"Content-Type": "application/json", "Authorization": token }}

    const save = async (attributes: any) => {
        try {
            await axios.post(Urls.ORDERS, attributes, headers)

            onSaved()
        } catch(error) {
            enqueueSnackbar("Fehler beim Report erstellen")
        }
    }

    return (
        <DialogAnimate open={open} onClose={() => {}}>
            <DialogTitle>{selectedReport ? 'Bearbeiten' : 'Auftrag Hinzufügen'}</DialogTitle>
            <OrderForm onCancel={() => onCallback()} order={undefined} onSave={save}/>
        </DialogAnimate>
    )
}